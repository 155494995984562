<template>
  <div>
    <div class="fankui_top">反馈信息：</div>
    <div>
      <van-field
        class="fankui_textarea"
        v-model="list.content"
        disabled
        placeholder="请输入反馈内容"
        nput-align="right"
      ></van-field>
    </div>
    <!-- <div class="fankui_top">图片说明：</div>
    <div class="fankui_top">
      <van-image class="fankui_img" :src="logo"></van-image>
      <van-image class="fankui_img" :src="logo"></van-image>
    </div> -->
    <div v-if="isSolved" class="fankui_btn_out">
      <span class="fankui_btn_active" @click="solved"
        ><span class="iconfont icon-like"></span> 已解决</span
      >
      <span class="fankui_btn" @click="noSolved"
        ><span class="iconfont icon-zan3"></span> 未解决</span
      >
    </div>
    <div v-else class="fankui_btn_out">
      <span class="fankui_btn" @click="solved"
        ><span class="iconfont icon-like"></span> 已解决</span
      >
      <span class="fankui_btn_active" @click="noSolved"
        ><span class="iconfont icon-zan3"></span> 未解决</span
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: {
        content:
          "天青色等烟雨,天青色等烟雨天青色等烟雨天青色等烟雨天青色等烟雨天青色等烟雨天青色等烟雨天青色等烟雨天青色等烟雨",
        status: 0, //已解决
        // status:1,//未解决
      },
      isSolved: false,
      logo: require("../assets/images/logo.png"),
      infoId: "",
      id: "",
      pid: "",
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    this.id = id;
    let infoId = this.$route.params.id;
    // console.log(infoId);
    this.getInfo(infoId);
  },
  methods: {
    //反馈详情
    getInfo(val) {
      this.$axios
        .get("/message/info?id=" + val)
        .then((res) => {
          // console.log(res);
          this.list = res.data.data;
          // for (var i = 0; i < this.list.length; i++) {
          //   this.list[i].create_time = formatMinute(this.list[i].create_time);
          // }
        })
        .catch(() => {});
    },
    //前往问题反馈页面
    toAddFankui() {
      this.$router.push({ name: "addFankui" });
    },
    solved() {},
    noSolved() {},
  },
};
</script>
<style scoped>
.fankui_top {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.9375rem;
}

.fankui_textarea {
  border: 0.0625rem solid #ddd;
  padding: 0.625rem 0.3125rem;
  box-sizing: border-box;
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.9375rem;
  border-radius: 0.625rem;
  height: 12.5rem;
}

.fankui_img {
  width: 4.375rem;
  height: 4.375rem;
  margin-right: 0.625rem;
  border: 0.0625rem solid #eee;
  border-radius: 0.3125rem;
}

.fankui_btn,
.fankui_btn_active {
  padding: 0.3125rem 0.625rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.9375rem;
  border: 0.0625rem solid #aaa;
  margin: 0 1.25rem;
}

.fankui_btn_active {
  background-color: #3e90ff;
  color: #fff;
  border: 0.0625rem solid #3e90ff;
}
.fankui_btn_out {
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
}
</style>
<style>
</style>
